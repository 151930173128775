$main: #f7f4ef;
// $main: #f0e2d0;
// $main: #f0d8bb;
$white: #ffffff;
$black: #1e1f13;

@font-face {
  font-family: BVSerif;
  src: url("./fonts/Bon\ Vivant\ Serif\ Bold.otf") format("opentype");
}

@font-face {
  font-family: BVScript;
  src: url("./fonts/BonVivant-Regular.otf") format("opentype");
}

* {
  box-sizing: border-box;
  font-family: "HelveticaNeue-CondensedBold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 900;
}

body {
  padding: 0;
  margin: 0;
  background-color: $main;
  color: $black;
  &:before {
    animation: grain 8s steps(10) infinite;
    background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png");
    content: "";
    height: 300%;
    left: -50%;
    opacity: 0.3;
    position: fixed;
    top: -100%;
    width: 300%;
  }
  &.no-scroll {
    overflow-y: hidden;
  }
}

@keyframes grain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
}

//Header

.container {
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
  width: auto;
  height: 100%;
  &.fluid {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1216px) {
    max-width: 1152px;
  }
  @media (min-width: 1408px) {
    max-width: 1560px;
  }

  .row {
    display: flex;
    align-items: center;
    &.space-between {
      justify-content: space-between;
    }
    &.center {
      justify-content: center;
    }
  }
}

header {
  font-size: 16px;
  position: fixed;
  z-index: 99;
  width: 100%;
  font-weight: "700";
  .container {
    .row {
      height: 128px;
      .logo {
        a {
          color: $black;
          text-decoration: none;
        }
      }
      .menu {
        cursor: pointer;
        border-radius: 100%;
        border: 1px solid $black;
        height: 80px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease-in-out;
        &:hover {
          color: $white;
          background: $black;
        }
      }
    }
  }
}

main {
  .container {
    position: relative;
    .row {
      height: 100vh;
      align-items: center;
      .image-container {
        position: relative;
        .thumbnail {
          overflow: hidden;
          position: relative;
          object-fit:cover;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .information {
          position: absolute;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          text-transform: uppercase;
          .location {
            span:nth-child(2) {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

//single
.single {
  .container {
    .top-row {
      height: 50vh;
      width: 100%;
      align-items: flex-end;
      @media (max-width: 800px) {
        height: 40vh;
      }
      @media (max-width: 500px) {
        height: 30vh;
      }
      .top {
        padding-bottom: 15px;
        .details {
          // background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .location {
            span:nth-child(2) {
              margin-left: 16px;
            }
          }
          .git {
            text-transform: uppercase;
          }
          @media only screen and (max-width: 800px) {
            padding: 0 10px;
          }
        }
        .spotlight {
          overflow: hidden;
          @media only screen and (max-width: 500px) {
            margin-left: 20px;
          }
          .first {
            margin-right: 72px;
          }
          span {
            display: inline-block;
            position: relative;
            font-weight: 400;
            font-size: 150px;
            font-family: "BVSerif";
            @media (max-width: 1440px) {
              font-size: 116px;
            }
            @media only screen and (max-width: 800px) {
              font-size: 80px;
            }
            @media only screen and (max-width: 500px) {
              font-size: 50px;
            }
          }
        }
      }
    }
    .bottom-row {
      height: 50vh;
      position: relative;
      .bottom {
        height: 100%;
        width: 100%;
        .thumbnail-single {
          width: 100%;
          height: 800px;
          margin: 0 auto;
          overflow: hidden;
          position: absolute;
          left: 0;
          right: 0;
          @media (max-width: 800px) {
            height: 400px;
          }
          @media (max-width: 500px) {
            height: 200px;
          }
          .frame-single {
            display: flex;
            justify-content: center;
            img {
              position: absolute;
              width: 100%;
              @media (max-width: 750px) {
                width: 750px;
                margin: 0 auto;
              }
            }
          }
    }
  }
      .scroll-for-more {
        position: absolute;
        bottom: 200px;
        left: 10%;
        z-index: 20;
        .icon {
          svg {
            height: auto;
            width: 28px;
          }
        }
        .text {
          margin-top: 8px;
          color: $white;
          text-transform: uppercase;
        }
      }
    }
  }
}

.detailed-information {
  margin-top: 200px;
  height: 250px;
  .container {
    .row {
      justify-content: space-between;
      align-items: flex-start;
      @media (max-width: 900px) {
        margin-top: -130px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }
      h2 {
        font-size: 28px;
        filter: invert(1);
        mix-blend-mode: difference;
        margin-top: 44px;
        @media (max-width: 1216px) {
          margin-right: 30px;
        }
        @media (max-width: 900px) {
          font-size: 58px;
          margin: 0;
        }
      }
      p {
        padding-top: 42px;
        font-family: "Helvetica Neue";
        font-size: 16;
        line-height: 28px;
        font-weight: 400;
        width: 740px;
        filter: invert(1);
        mix-blend-mode: difference;
        @media (max-width: 900px) {
          width: 100%;
          margin-top: -40px;
        }
      }
      i {
        font-weight: 400;
      }
    }
  }
}

.portfolio {
  width: 100vw;
  height: 60vh;
  text-align: center;
  display: grid;
  justify-content: center;
  align-content: center;
  // background-color: lightsalmon;
  @media only screen and (max-width: 800px) {
    margin-top: 90px;
  }
  @media only screen and (max-width: 500px) {
    margin-top: 200px;
  }
  @media only screen and (max-width: 350px) {
    margin-top: 300px;
  }
  .port-text {
    color: $black;
    font-weight: 400;
    font-size: 180px;
    font-family: "BVSerif";
    @media only screen and (max-width: 800px) {
      font-size: 90px;
    }
    @media only screen and (max-width: 500px) {
      font-size: 65px;
    }
  }
  .soon-text {
    position: relative;
    top: -90px;
    font-weight: 400;
    font-size: 80px;
    font-family: "BVScript";
    filter: invert(1);
    mix-blend-mode: difference;
    @media only screen and (max-width: 800px) {
      font-size: 44px;
      top: -36px;
    }
    @media only screen and (max-width: 500px) {
      font-size: 32px;
      top: -33px;
    }
  }
}
